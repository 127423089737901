import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, YAxis } from 'recharts'
import { formatDateExact, formatDateSimple, formatPercentage } from 'toolbox/format'
import { SimpleSpinner } from './SimpleSpinner'
import { useMemo, useState } from 'react'
import { timeTabs } from '../../state/slices/app/allBrokersHistory'
import TimeFilterTabs from 'components/common/TimeFilterTabs'
import { isEmptyOrNil } from 'toolbox/account'
import { Label } from './Label'

interface DataItem {
  timestamp: number
  borrowRate: number
  lendRate: number
}

export function TwoLineInterestRates({ isLoaded, data }: { isLoaded: boolean; data: DataItem[] }) {
  const [activeData, setActiveData] = useState(null)
  const activeLendRate = activeData ? activeData.lendRate : null
  const activeBorrowRate = activeData ? activeData.borrowRate : null
  const [activeTab, setActiveTab] = useState('1M')
  const numberOfDays = timeTabs.find((tab) => tab.label === activeTab).days

  const handleMouseMove = (data) => {
    if (data.activePayload) {
      setActiveData(data.activePayload[0].payload)
    }
  }

  const handleMouseLeave = () => {
    setActiveData(null)
  }

  const filteredData = useMemo(
    () => data?.filter((d) => d.timestamp * 1000 > Date.now() - numberOfDays * 24 * 60 * 60 * 1000),
    [data, numberOfDays]
  )

  const chartData = useMemo(
    () =>
      filteredData?.map((item) => {
        return {
          time: formatDateSimple(item.timestamp),
          exactTime: formatDateExact(item.timestamp),
          borrowRate: parseFloat(item.borrowRate.toFixed(4)) * 100,
          lendRate: parseFloat(item.lendRate.toFixed(4)) * 100
        }
      }),
    [filteredData]
  )

  const hoverIndex = useMemo(
    () =>
      chartData?.findIndex(
        (d) => d.lendRate === activeLendRate && d.borrowRate === activeBorrowRate
      ),
    [chartData, activeLendRate, activeBorrowRate]
  )

  const slicedChartData = useMemo(
    () => chartData?.slice(0, hoverIndex + 1),
    [chartData, hoverIndex]
  )

  if (!data || isEmptyOrNil(data) || !isLoaded)
    return (
      <div className="chart-container">
        <SimpleSpinner />
      </div>
    )

  const finalBorrowRate =
    slicedChartData[slicedChartData.length - 1]?.borrowRate ||
    chartData[chartData.length - 1]?.borrowRate

  const finalLendRate =
    slicedChartData[slicedChartData.length - 1]?.lendRate ||
    chartData[chartData.length - 1]?.lendRate

  const rateDataPoints: DataPointProps[] = [
    {
      label: 'Earn APY',
      value: activeData
        ? formatPercentage(activeLendRate / 100)
        : formatPercentage(finalLendRate / 100),
      tooltip: `Earnings from deposits are boosted by interest on loans`,

      isLoading: !isLoaded,
      colorBox: 'green'
    },
    {
      label: 'Borrow APR',
      value: activeData
        ? formatPercentage(activeBorrowRate / 100)
        : formatPercentage(finalBorrowRate / 100),
      tooltip: 'Interest on loans always exceeds earnings on deposits',
      isLoading: !isLoaded,
      colorBox: 'blue'
    }
  ]

  const timeTabsProps = {
    activeTab,
    setActiveTab
  }

  return (
    <div className="chart-container">
      <div className="points-line">
        <div className="cluster-points">
          <DataPoint {...rateDataPoints[0]} />
          <DataPoint {...rateDataPoints[1]} />
        </div>
        <TimeFilterTabs {...timeTabsProps} />
      </div>
      <ResponsiveContainer width={'100%'} height={375}>
        {isLoaded ? (
          <LineChart
            width={500}
            height={300}
            data={chartData}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            margin={{
              top: 50
            }}>
            <YAxis
              domain={[0, 'dataMax']}
              hide={true}
              allowDataOverflow={false}
              padding={{ bottom: 50 }}
            />
            <XAxis
              dataKey="time"
              axisLine={false}
              tickLine={false}
              markerHeight={100}
              allowDataOverflow={false}
              interval={'preserveStartEnd'}
            />
            <Tooltip
              content={
                <Label
                  value={`${activeData?.lendRate.toFixed(2)}%`}
                  secondValue={`${activeData?.borrowRate.toFixed(2)}%`}
                  thirdValue={activeData?.exactTime}
                  labels={{
                    value: 'Earn APY (Avg)',
                    secondValue: 'Borrow APR (Avg)',
                    thirdValue: 'Exact Time'
                  }}
                />
              }
            />
            <Line dataKey="borrowRate" stroke="#f8d24a" dot={false} strokeWidth={1.5} />
            <Line dataKey="lendRate" stroke="#00DD5C" dot={false} strokeWidth={1.5} />
          </LineChart>
        ) : (
          <div className="chart-container">
            <SimpleSpinner />
          </div>
        )}
      </ResponsiveContainer>
    </div>
  )
}
