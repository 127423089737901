import moveLogo from 'assets/logos/movement.svg'
import { doMoveConversion } from 'state/thunks/doMoveConversion'

export function MOVECoinConversion({ dispatch, account, signAndSubmitTransaction }) {
  const convertMOVE = () => {
    dispatch(
      doMoveConversion({
        address: account.address,
        signAndSub: signAndSubmitTransaction
      })
    )
  }

  return (
    <div className="move-conversion-banner">
      <a onClick={() => convertMOVE()}>
        <div className="move-conversion-banner__left">
          <img src={moveLogo} alt="MOVE" />
          <div className="move-conversion-banner__content">
            <h3>Convert your MOVE coins!</h3>
            <p>
              You have a MOVE coin balance in your wallet. Please convert to a MOVE fungible asset
              balance by clicking here if you wish to deposit it to MovePosition.
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}
