import React, { useState } from 'react'
import { doMoneyGun } from 'state/thunks/doMoneyGun'

export function MoneygunDropdown({
  isEnabled,
  connected,
  dispatch,
  account,
  signAndSubmitTransaction,
  isMSafe
}) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleAction = (coin) => {
    dispatch(
      doMoneyGun({
        address: account.address,
        signAndSub: signAndSubmitTransaction,
        coin: coin,
        isMSafe
      })
    )
    setIsOpen(false) // Close the dropdown after action
  }

  return (
    <div className="money-dropdown">
      {isEnabled && connected && (
        <button
          className="money-dropdown-toggle sp-btn tertiary global-mr"
          onClick={toggleDropdown}>
          Faucet
        </button>
      )}
      {isOpen && isEnabled && connected && (
        <div className="money-dropdown-menu">
          <button
            onClick={() => window.open('https://faucet.movementnetwork.xyz/', '_blank').focus()}>
            MOVE
          </button>
          <button onClick={() => handleAction('MBTC')}>MBTC</button>
          <button onClick={() => handleAction('STBTC')}>STBTC</button>
          <button onClick={() => handleAction('USDC')}>USDC</button>
          <button onClick={() => handleAction('USDT')}>USDT</button>
          <button onClick={() => handleAction('WBTC')}>WBTC</button>
          <button onClick={() => handleAction('WETH')}>WETH</button>
          <button onClick={() => handleAction('EZETH')}>EZETH</button>
        </div>
      )}
    </div>
  )
}

export default MoneygunDropdown
