import { combineReducers } from 'redux'
import activeUserHistory from 'state/slices/app/activeUserHistory'
import allBrokersHistory from 'state/slices/app/allBrokersHistory'
import allUsersHistory from 'state/slices/app/allUsersHistory'
import meta from 'state/slices/app/meta'
import brokers from 'state/slices/app/brokers'
import priceHistories from 'state/slices/app/priceHistories'
import pointsTotals from 'state/slices/app/points'
import strategies from 'state/slices/app/strategies'

export const app = combineReducers({
  meta,
  activeUserHistory,
  allBrokersHistory,
  allUsersHistory,
  brokers,
  priceHistories,
  pointsTotals,
  strategies
})
