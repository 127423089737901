import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { AptosConfig, Aptos, Network, MoveResource } from '@aptos-labs/ts-sdk'
import { postTransactionRefresh } from 'state/fetch'
import { InputTransactionData, useWallet } from '@aptos-labs/wallet-adapter-react'
import { isEmptyOrNil } from 'toolbox/account'
import { AccountArgs, WaitArgs } from './doTx'
import { eMessage } from 'toolbox/format'

export const COIN_A = 'CoinA'
export const COIN_B = 'CoinB'
export const COIN_C = 'CoinC'
export const STH_APT = 'StakedThalaAPT'
export const AM_APT = 'AmnisAPT'
export const STAKED_APT = 'StakedAPT'
export const USDT_WH = 'USDTWormhole'
export const USDC = 'USDC'
export const USDT_LZ = 'USDTLZ'
export const WETH = 'WETH'
export const WETH_WH = 'WETHWormhole'
export const EZETH = 'EZETH'
export const THL = 'THL'
export const WBTC = 'WBTC'
export const ABTC = 'aBTC'
export const STBTC = 'STBTC'
export const MBTC = 'MBTC'
export const TRU_APT = 'truAPT'
export const USDT = 'USDT'

export type SimpleCoin =
  | typeof COIN_A
  | typeof COIN_B
  | typeof COIN_C
  | typeof WBTC
  | typeof STH_APT
  | typeof AM_APT
  | typeof STAKED_APT
  | typeof USDT_WH
  | typeof USDC
  | typeof USDT_LZ
  | typeof WETH
  | typeof WETH_WH
  | typeof EZETH
  | typeof THL
  | typeof ABTC
  | typeof TRU_APT
  | typeof USDT

export type MoneyGunPayload = {
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  coin: SimpleCoin
  isMSafe?: boolean
}

type SignAndSubmitTransactionArgs = Parameters<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
type SignAndSubmitTransactionReturnType = ReturnType<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
export type SignAndSubmitTransactionCallback = (
  ...args: SignAndSubmitTransactionArgs
) => SignAndSubmitTransactionReturnType

export const doMoneyGun = createAsyncThunk(
  'doMoneyGun',
  async (payload: MoneyGunPayload): Promise<any> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const aptosConfig = new AptosConfig({
      network: Network.CUSTOM,
      fullnode: process.env.REACT_APP_APTOS_MAIN_URL,
      faucet: process.env.REACT_APP_APTOS_FAUCET_URL
    })
    const aptos = new Aptos(aptosConfig)

    if (payload.isMSafe) {
      dismissNotifyThrow(
        'Faucet Not Available (MSafe)',
        'The faucet is not available in the MSafe experience. Please use faucet on the testnet app with another wallet, and send your test tokens to selected MSafe wallet.  Other app transactions are fully supported.'
      )
    }

    const rootAddr = process.env.REACT_APP_APTOS_FAUCET_ADDRESS
    if (!rootAddr) throw new Error('REACT_APP_APTOS_FAUCET_ADDRESS not set')

    const coinA = `${rootAddr}::coins::CoinA`
    const coinB = `${rootAddr}::coins::CoinB`
    const coinC = `${rootAddr}::coins::CoinC`
    const sthAPT = `${rootAddr}::coins::StakedThalaAPT`
    const amAPT = `${rootAddr}::coins::AmnisAPT`
    const stakedAPT = `${rootAddr}::coins::StakedAPT`
    const usdtWh = `${rootAddr}::coins::USDTWormhole`
    const usdtLz = `${rootAddr}::coins::USDTLZ`
    const weth = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::WETH`
    const wethWh = `${rootAddr}::coins::WETHWormhole`
    const ezeth = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::EZETH`
    const thl = `${rootAddr}::coins::THL`
    const wbtc = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::WBTC`
    const abtc = `${rootAddr}::coins::ABTC`
    const stBTC = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::STBTC`
    const mBTC = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::MBTC`
    const truAPT =
      '0x3e472d6bbcf4d1651e01430eb758ebeb955f26792134e96ca8da5722a85dc995::coins::TruAPT'
    const usdt = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::USDt`
    const usdc = `0xccd2621d2897d407e06d18e6ebe3be0e6d9b61f1e809dd49360522b9105812cf::coins::USDC`

    const coinToCoinMap = {
      [COIN_A]: coinA,
      [COIN_B]: coinB,
      [COIN_C]: coinC,
      [STH_APT]: sthAPT,
      [AM_APT]: amAPT,
      [STAKED_APT]: stakedAPT,
      [USDT_WH]: usdtWh,
      [USDC]: usdc,
      [USDT_LZ]: usdtLz,
      [WETH]: weth,
      [WETH_WH]: wethWh,
      [EZETH]: ezeth,
      [THL]: thl,
      [WBTC]: wbtc,
      [ABTC]: abtc,
      [STBTC]: stBTC,
      [MBTC]: mBTC,
      [TRU_APT]: truAPT,
      [USDT]: usdt
    }

    const useCoin = coinToCoinMap[payload.coin]

    if (isEmptyOrNil(useCoin)) {
      throw new Error(`Invalid coin: ${payload.coin}`)
    }

    const args: AccountArgs = {
      accountAddress: payload.address
    }

    let resources: MoveResource[] = []
    let doRegister = false

    const moneygunTx: InputTransactionData = {
      sender: payload.address,
      data: {
        function: `${rootAddr}::moneygun::shoot`,
        typeArguments: [useCoin],
        functionArguments: []
      }
    }
    try {
      toastLoad('Submitting faucet request...')
      const shot = await payload.signAndSub(moneygunTx)
      const args: WaitArgs = {
        transactionHash: shot.hash,
        options: {
          checkSuccess: true
        }
      }
      await aptos.waitForTransaction(args)
      toastSuccess('Transaction successful')
      postTransactionRefresh(payload.address)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Transaction Not Completed', eMessage(e))
    }
  }
)
