import { createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchPortfolios } from 'state/thunks/fetchPortfolios'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { calcAPY } from 'toolbox/calc'
import { types } from '@concordia/super-sdk'
import {
  getBrokerByLoanNoteName,
  selectBrokersLoaded,
  selectBrokersWithMeta,
  selectPricesFromBrokers
} from '../app/brokers'
import { Evaluation } from '@concordia/super-json-api-client'
import { DUST_LIMIT } from 'state/tokens'

type State = Loadable<types.SPortfolio | null>
export const initialState = initLoadable<types.SPortfolio | null>(null)

const portfolio = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    resetPortfolio: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolios.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchPortfolios.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchPortfolios.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetPortfolio } = portfolio.actions
export default portfolio.reducer
export const selectPortfolio = (s: RootState) => s.user.portfolio.value
export const selectPortfolioBusy = (s: RootState) => s.user.portfolio.status === 'busy'
export const selectPortfolioLoaded = (s: RootState) => s.user.portfolio.loadedOnce
export const selectPortfolioErrored = (s: RootState) => s.user.portfolio.status === 'errored'

export const selectPositions = createSelector(
  [selectPortfolio, selectPortfolioLoaded],
  (portfolio, loaded) => {
    if (!loaded || !portfolio) {
      return null
    }
    return { collaterals: portfolio?.collaterals, liabilities: portfolio?.liabilities }
  }
)

export const selectFormattedPositions = createSelector([selectPositions], (positions) => {
  if (!positions) {
    return { collaterals: {}, liabilities: {} }
  }
  const c = positions?.collaterals
  const l = positions?.liabilities
  const collaterals: types.NameBalanceMap = {}
  const liabilities: types.NameBalanceMap = {}

  if (!isEmptyOrNil(c)) {
    c.forEach((c) => {
      collaterals[c.instrument.name] = c.scaledAmount
    })
  }
  if (!isEmptyOrNil(l)) {
    l.forEach((l) => {
      liabilities[l.instrument.name] = l.scaledAmount
    })
  }
  const formattedPositions: FormattedPositions = {
    collaterals,
    liabilities
  }
  return formattedPositions
})

export interface PositionSummary {
  exchangeRateBalance: number
  exchangeRateValue: number
  hasPosition
  borrowAPR: number
  supplyAPY: number
}

export const calcSupplyData = (
  positions: FormattedPositions,
  broker: MetaBroker,
  utilization: number
): PositionSummary => {
  const depNoteName = broker.depositNote.name
  const assetPrice = broker.underlyingAsset.price
  const userSupply = positions.collaterals[depNoteName] || 0
  const exchangeRateBalance = userSupply * broker.depositNoteExchangeRate || 0
  const exchangeRateValue = exchangeRateBalance * assetPrice
  const borrowAPY = calcAPY(broker.interestRate)
  const supplyAPY = borrowAPY * utilization
  const hasSupply = exchangeRateBalance >= DUST_LIMIT

  return {
    exchangeRateBalance,
    exchangeRateValue,
    supplyAPY: supplyAPY,
    borrowAPR: broker.interestRate,
    hasPosition: hasSupply
  }
}

export const calcBorrowData = (
  positions: FormattedPositions,
  broker: MetaBroker
): PositionSummary => {
  const loanNoteName = broker.loanNote.name
  const assetPrice = broker.underlyingAsset.price
  const userLiab = positions.liabilities[loanNoteName] || 0
  const exchangeRateBalance = userLiab * broker.loanNoteExchangeRate || 0
  const exchangeRateValue = exchangeRateBalance * assetPrice || 0
  //uses apy for convention
  const hasBorrow = exchangeRateBalance >= DUST_LIMIT

  return {
    exchangeRateBalance,
    exchangeRateValue,
    borrowAPR: broker.interestRate,
    supplyAPY: 0,
    hasPosition: hasBorrow
  }
}

export const selectTotalLiability = createSelector(
  [selectPortfolio, selectPortfolioLoaded, selectPricesFromBrokers],
  (portfolio, portfolioLoaded, prices) => {
    if (!portfolioLoaded) {
      return 0
    }
    const liabilities = portfolio?.liabilities
    let totalLiability = 0
    liabilities?.forEach((l) => {
      const price = prices[l.instrument.name]
      totalLiability += l.scaledAmount * price
    })
    return totalLiability ? totalLiability : 0
  }
)

export const selectTotalCollateral = createSelector(
  [selectPortfolio, selectPortfolioLoaded, selectPricesFromBrokers],
  (portfolio, portfolioLoaded, prices) => {
    if (!portfolioLoaded) {
      return 0
    }
    const collaterals = portfolio?.collaterals
    let totalCollateral = 0
    collaterals?.forEach((c) => {
      const price = prices[c.instrument.name]
      totalCollateral += c.scaledAmount * price
    })
    return totalCollateral
  }
)

export const selectMinimumRequiredEquity = createSelector(
  [selectPortfolio, selectPortfolioLoaded],
  (portfolio, portfolioLoaded) => {
    if (!portfolioLoaded) {
      return 0
    }
    return portfolio?.risk?.requiredEquity || 0
  }
)

export const selectFreeEquity = createSelector(
  [selectPortfolioLoaded, selectTotalCollateral, selectTotalLiability, selectMinimumRequiredEquity],
  (portfolioLoaded, collat, liab, min) => {
    if (!portfolioLoaded) {
      return 0
    }
    return collat - liab - min || 0
  }
)

export const selectEquity = createSelector(
  [selectPortfolioLoaded, selectTotalCollateral, selectTotalLiability],
  (portfolioLoaded, collat, liab) => {
    if (!portfolioLoaded) {
      return 0
    }
    return collat - liab || 0
  }
)

export const selectHealthFactor = createSelector(
  [selectEquity, selectMinimumRequiredEquity, selectPortfolioLoaded],
  (equity, minRequired, portfolioLoaded) => {
    if (!portfolioLoaded) {
      return 0
    }
    return equity / minRequired
  }
)

export const calcHealthFactor = (simRisk: Evaluation) => {
  if (!simRisk) {
    return 0
  }
  const equity = simRisk.total_collateral - simRisk.total_liability
  const minReq = simRisk.mm
  return equity / minReq
}

export const selectPositionByName = createSelector(
  [selectFormattedPositions],
  (positions) => (name: string) => {
    return positions.collaterals[name] || positions.liabilities[name] || 0
  }
)

export const selectMaxBorrows = createSelector(
  [selectPortfolioLoaded, selectPortfolio],
  (loaded, portfolio) => {
    if (!loaded || !portfolio) {
      return {}
    }
    return portfolio.maxBorrow
  }
)

export interface CalcedMaxBorrow {
  maxBorrowUnderlying: number
  maxBorrowValueUSD: number
}

export const selectMaxBorrowForAsset = createSelector(
  [
    selectPortfolioLoaded,
    selectBrokersLoaded,
    selectBrokersWithMeta,
    selectMaxBorrows,
    selectPricesFromBrokers,
    (state, noteName) => noteName
  ],
  (loaded, brokersLoaded, brokers, maxBorrows, prices, noteName) => {
    if (!loaded || !brokersLoaded) {
      return { maxBorrowUnderlying: 0, maxBorrowValueUSD: 0 }
    }
    const noteMax = getMaxBorrowForLoanNote(noteName, maxBorrows)
    const broker = getBrokerByLoanNoteName(brokers, noteName)
    const underlyingName = broker?.underlyingAsset.name
    const price = prices[underlyingName]
    const maxBorrowUnderlying = noteMax * broker?.loanNoteExchangeRate
    const maxBorrowValueUSD = maxBorrowUnderlying * price
    if (maxBorrowUnderlying < 0 || maxBorrowValueUSD < 0) {
      return { maxBorrowUnderlying: 0, maxBorrowValueUSD: 0 }
    }
    return { maxBorrowUnderlying, maxBorrowValueUSD }
  }
)

export const getMaxBorrowForLoanNote = (noteName: string, maxBorrows: types.SMaxBorrow) => {
  const mb = maxBorrows[noteName]
  if (!mb) {
    return 0
  }
  return Number(mb)
}

export const isGreenZone = (hf: number) => {
  return hf > 1.5
}

export const isYellowZone = (hf: number) => {
  return hf <= 1.5 && hf > 1.2
}

export const isRedZone = (hf: number) => {
  return hf <= 1.2
}

export const isUnhealthy = (hf: number) => {
  return hf <= 1
}
