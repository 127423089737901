import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { AptosConfig, Aptos, Network, MoveResource } from '@aptos-labs/ts-sdk'
import { postTransactionRefresh } from 'state/fetch'
import { InputTransactionData, useWallet } from '@aptos-labs/wallet-adapter-react'
import { WaitArgs } from './doTx'
import { eMessage } from 'toolbox/format'

export type MoveConversionPayload = {
  address: string
  signAndSub: SignAndSubmitTransactionCallback
}

type SignAndSubmitTransactionArgs = Parameters<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
type SignAndSubmitTransactionReturnType = ReturnType<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
export type SignAndSubmitTransactionCallback = (
  ...args: SignAndSubmitTransactionArgs
) => SignAndSubmitTransactionReturnType

export const doMoveConversion = createAsyncThunk(
  'doMoveConversion',
  async (payload: MoveConversionPayload): Promise<any> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const aptosConfig = new AptosConfig({
      network: Network.CUSTOM,
      fullnode: process.env.REACT_APP_APTOS_MAIN_URL,
      faucet: process.env.REACT_APP_APTOS_FAUCET_URL
    })
    const aptos = new Aptos(aptosConfig)

    const tx: InputTransactionData = {
      sender: payload.address,
      data: {
        function: '0x1::coin::migrate_to_fungible_store',
        typeArguments: ['0x1::aptos_coin::AptosCoin'],
        functionArguments: []
      }
    }
    try {
      toastLoad('Submitting MOVE conversion request...')
      const convert = await payload.signAndSub(tx)
      const args: WaitArgs = {
        transactionHash: convert.hash,
        options: {
          checkSuccess: true
        }
      }
      await aptos.waitForTransaction(args)
      toastSuccess('Transaction successful')
      postTransactionRefresh(payload.address)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Transaction Not Completed', eMessage(e))
    }
  }
)
