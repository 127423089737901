import { Modal } from 'layout/Modal'
import { useAppSelector } from 'state/hooks'
import {
  selectTxError,
  selectTxSuccess,
  setTxErrorState,
  setTxSuccessState
} from 'state/slices/ui/transaction'
import {
  closeForm,
  closeMultiplyForm,
  selectActiveBroker,
  selectFormOpen,
  selectMultiplyFormOpen
} from 'state/slices/ui/form'
import TxForm from 'components/txForm/TxForm'
import { selectFormattedPositions } from 'state/slices/user/portfolio'
import { selectPortfolioLoaded } from 'state/slices/user/portfolio'
import { isEmptyOrNil } from 'toolbox/account'
import TransferSuccess from 'components/txForm/TransferSuccess'
import { ErrorModal } from 'components/wallet/ErrorModal'
import { closeBlock, selectShowBlockModal } from 'state/slices/ui/dash'
import { BlockedModal } from './BlockedModal'
import {
  closeDoReferModal,
  closeReferralModal,
  selectDoReferModalOpen,
  selectReferralModalOpen
} from 'state/slices/app/points'
import { LinkModal } from 'components/points/LinkModal'
import { DoReferModal } from 'components/points/DoReferModal'
import Multiform from 'components/multiply/MultiForm'
import MobileModal from './MobileModal'

export function AppModals() {
  const positions = useAppSelector(selectFormattedPositions)
  const isLoadedUser = useAppSelector(selectPortfolioLoaded)
  const isFormOpen = useAppSelector(selectFormOpen)
  const formBroker = useAppSelector(selectActiveBroker)
  const txSuccessState = useAppSelector(selectTxSuccess)
  const txSuccessOpen = !isEmptyOrNil(txSuccessState)
  const txError = useAppSelector(selectTxError)
  const isErrorOpen = !isEmptyOrNil(txError)
  const showBlockModal = useAppSelector(selectShowBlockModal)
  const referralModalOpen = useAppSelector(selectReferralModalOpen)
  const doReferModalOpen = useAppSelector(selectDoReferModalOpen)
  const isMultiFormOpen = useAppSelector(selectMultiplyFormOpen)

  return (
    <>
      <Modal open={isFormOpen} onClose={closeForm} transition={'bot-up'}>
        <TxForm broker={formBroker} isLoadedUser={isLoadedUser} positions={positions} />
      </Modal>
      <Modal open={isMultiFormOpen} onClose={closeMultiplyForm} transition={'bot-up'}>
        <Multiform broker={formBroker} isLoadedUser={isLoadedUser} positions={positions} />
      </Modal>
      <Modal open={txSuccessOpen} onClose={() => setTxSuccessState(null)} transition={'bot-up'}>
        <TransferSuccess />
      </Modal>
      <Modal open={isErrorOpen} onClose={() => setTxErrorState(null)} transition={'bot-up'}>
        <ErrorModal {...txError} />
      </Modal>
      <Modal open={showBlockModal} onClose={closeBlock} transition={'top-down'}>
        <BlockedModal />
      </Modal>
      <Modal open={referralModalOpen} onClose={closeReferralModal} transition={'top-down'}>
        <LinkModal />
      </Modal>
      <Modal open={doReferModalOpen} onClose={closeDoReferModal} transition={'top-down'}>
        <DoReferModal />
      </Modal>
      <MobileModal open={isFormOpen} onClose={closeForm} transition={'bot-up'}>
        <TxForm broker={formBroker} isLoadedUser={isLoadedUser} positions={positions} />
      </MobileModal>
      <MobileModal
        open={txSuccessOpen}
        onClose={() => setTxSuccessState(null)}
        transition={'bot-up'}>
        <TransferSuccess />
      </MobileModal>
      <MobileModal open={isErrorOpen} onClose={() => setTxErrorState(null)} transition={'bot-up'}>
        <ErrorModal {...txError} />
      </MobileModal>
      {/* <MobileModal open={showBlockModal} onClose={closeBlock} transition={'top-down'}>
        <BlockedModal />
      </MobileModal> */}
    </>
  )
}
