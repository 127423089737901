import { MetaBroker } from 'state/mock'
import { BORROW_TAB, REPAY_TAB, WITHDRAW_TAB } from 'state/slices/ui/form'
import { FormTab } from 'state/slices/ui/form'

export const calcAPY = (apr: number) => {
  const e = Math.E
  const exp = e ** apr
  return exp - 1
}

export const estimateNewAvailable = (b: MetaBroker, value: string, tab: FormTab) => {
  const val = parseFloat(value)
  let coins = val
  if (tab === WITHDRAW_TAB || tab === BORROW_TAB) {
    coins = val * -1
  }

  const available = b.scaledAvailableLiquidityUnderlying
  const nextAvailable = available + coins
  return nextAvailable
}

export const estimateNewBorrowed = (currentBorrowVal: number, value: string, tab: FormTab) => {
  const val = parseFloat(value)
  let coins = 0
  if (tab === BORROW_TAB) {
    coins = val
  }
  if (tab === REPAY_TAB) {
    coins = val * -1
  }

  const nextBorrowed = currentBorrowVal + coins
  return nextBorrowed
}

export const calcNewUtil = (borrowed: number, total: number) => {
  const util = borrowed / total
  return util > 0 ? util : 0
}

export const calcUtil = (b: MetaBroker) => {
  const avail = b.scaledAvailableLiquidityUnderlying
  const total = b.scaledTotalBorrowedUnderlying + avail
  const util = b.scaledTotalBorrowedUnderlying / total
  return util > 0 ? util : 0
}

export function countUserAddresses(data) {
  // Initialize an empty object to store counts of each userAddress
  const counts = {}

  // Iterate over each key in the input object
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      // Extract the userAddress from the current item
      const userAddress = data[key].userAddress

      // If the userAddress is already in the counts object, increment its count
      // Otherwise, initialize it with a count of 1
      counts[userAddress] = counts[userAddress] ? counts[userAddress] + 1 : 1
    }
  }

  return counts
}

export function floorAndFixToEightDecimals(value) {
  let scaledValue = value * Math.pow(10, 8)

  scaledValue = Math.floor(scaledValue)

  return scaledValue / Math.pow(10, 8)
}
