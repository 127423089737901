// import { toastLoad, toastSuccess } from 'toolbox/toast'
// import { useNetwork, useSwitchNetwork } from 'wagmi'

export default function Footer() {
  // const { chains, switchNetworkAsync, switchNetwork, isLoading, pendingChainId } =
  //   useSwitchNetwork()
  // const { chain } = useNetwork()

  // async function switchEvmNetwork(id: number) {
  //   try {
  //     toastLoad(`Switching to ${chains.find((x) => x.id === id)?.name}`)
  //     await switchNetworkAsync(id)
  //     toastSuccess(`Switched to ${chains.find((x) => x.id === id)?.name}`)
  //   } catch (e: any) {
  //     console.log(e)
  //   }
  // }
  return (
    <div className="footer">
      <FooterLinks />
      {/* <p>Evm network: {chain?.name ? chain?.name : 'None'}</p>

      <div className="net-switch">
        {chains.map((x) => (
          <button
            className="sp-btn primary"
            disabled={!switchNetwork || x.id === chain?.id}
            key={x.id}
            onClick={() => switchEvmNetwork(x.id)}>
            {x.name}
            {isLoading && pendingChainId === x.id && ' (switching)'}
          </button>
        ))}
      </div> */}
    </div>
  )
}

function FooterLinks() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer-links">
      <p className="year">&copy;&nbsp;{currentYear}&nbsp;MovePosition</p>
    </div>
  )
}
