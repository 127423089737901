import tooltip from 'assets/icons/tooltip-black.svg'
import tip from 'assets/icons/tooltip-grey.svg'

export interface TooltipProps {
  label: string
  value: string
  normal?: boolean
}

export function Tooltip({ label, value, normal }: TooltipProps) {
  const noLabel = label === ''
  return (
    <div className="tip-box">
      {noLabel ? (
        <img src={normal ? tip : tooltip} alt="tooltip" className="tip-image" />
      ) : (
        <p className={`tip-label`}>{label}</p>
      )}
      <div className="tip-value">
        <p>{value}</p>
      </div>
    </div>
  )
}
