import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { SdkContext, SDK_CONTEXT } from 'state/context'
import { Routing } from './Routing'
import TopNav from 'layout/TopNav'
import {
  fetchAllBrokersHistoryData,
  fetchAllUsersHistoryData,
  fetchAppData,
  fetchActiveUserHistoryData,
  fetchHistoriesPrice,
  fetchPoints,
  fetchPortUser,
  fetchUserWalletBalances
} from 'state/fetch'
import { AppModals } from './AppModals'
import WidgetContainer from 'components/wallet/WidgetContainer'
import Footer from './Footer'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import {
  selectBrokersErrored,
  selectBrokersLoaded,
  selectUnderlyingNamesFromBrokers
} from 'state/slices/app/brokers'
import { useAppSelector } from 'state/hooks'
import { isEmptyOrNil } from 'toolbox/account'
import Hotjar from '@hotjar/browser'
import ReactGA from 'react-ga'
import { setTxErrorState } from 'state/slices/ui/transaction'
import { selectPortfolioErrored } from 'state/slices/user/portfolio'
import {
  closeBlock,
  resetBlocked,
  selectIsBlocked,
  selectShowBlockModal,
  setBlocked,
  showBlock
} from 'state/slices/ui/dash'
import { useLocation } from 'react-router-dom'
import { openDoReferModal, openReferralBanner } from 'state/slices/app/points'
import ReferralBanner from 'components/points/ReferralBanner'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export interface InnerContext {
  sdk: SdkContext
}

export interface AppContext {
  context: InnerContext
  setContext: Dispatch<SetStateAction<InnerContext>>
}

const DEFAULT_CONTEXT: AppContext = {
  context: { sdk: SDK_CONTEXT },
  setContext: () => {}
}

export const Context = createContext<AppContext>(DEFAULT_CONTEXT)

console.log('ENV: ', process.env.REACT_APP_ENV)
console.log('SUPER API URL:', process.env.REACT_APP_SUPER_JSON_API_URL)
console.log('APTOS ROOT: ', process.env.REACT_APP_APTOS_ROOT_ADDRESS)
console.log('APTOS FAUCET: ', process.env.REACT_APP_APTOS_FAUCET_ADDRESS)

const TRACKING_ID_GA = 'G-QPECQG93XF'
const SITE_ID_HOTJAR = 4939784

function InnerApp() {
  const [context, setContext] = useState(DEFAULT_CONTEXT.context)
  const { sdk } = context
  const { superSdk } = sdk
  const query = useQuery()
  let connected = false
  let accountAddress = null
  {
    const wallet = useWallet()
    const viewportfolio = query.get('viewportfolio')
    if (viewportfolio) {
      console.log('Viewing portfolio: ', viewportfolio)
      connected = true
      accountAddress = viewportfolio
    } else {
      connected = wallet.connected
      accountAddress = wallet.account?.address
    }
  }
  const underlyingNames = useAppSelector(selectUnderlyingNamesFromBrokers)
  const isBrokersLoaded = useAppSelector(selectBrokersLoaded)
  const brokersErroredAfterRetry = useAppSelector(selectBrokersErrored)
  const portfolioErroredAfterRetry = useAppSelector(selectPortfolioErrored)
  const isBlocked = useAppSelector(selectIsBlocked)
  const showBlockModal = useAppSelector(selectShowBlockModal)
  const { pathname } = useLocation()
  const dashSelected = pathname === '/'

  useEffect(() => {
    const checkIp = async () => {
      try {
        const isIpOk = await sdk.superSdk.fetcher.checkValidIp()
        console.log('checked IP: ', isIpOk)
        if (!isIpOk) {
          setBlocked()
          showBlock()
        } else {
          resetBlocked()
        }
      } catch (e) {
        console.log('Error checking IP region:', e.message)
        setBlocked()
        showBlock()
      }
    }

    checkIp()
  }, [sdk.superSdk.fetcher])

  useEffect(() => {
    if (!isBlocked && showBlockModal) {
      closeBlock()
    }
  }, [isBlocked, showBlockModal])

  useEffect(() => {
    const siteId = SITE_ID_HOTJAR
    const hotjarVersion = 6
    if (process.env.REACT_APP_ENV === 'stable-mainnet') {
      Hotjar.init(siteId, hotjarVersion, {
        debug: true
      })
      ReactGA.initialize(TRACKING_ID_GA)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  // load initial system data
  useEffect(() => {
    if (!superSdk) {
      return
    }
    fetchAppData()
  }, [superSdk])

  //load active user history
  useEffect(() => {
    if (!isEmptyOrNil(underlyingNames) && !isEmptyOrNil(accountAddress)) {
      fetchActiveUserHistoryData(underlyingNames, accountAddress as string)
    }
  }, [underlyingNames, accountAddress])

  //load all user histories
  useEffect(() => {
    fetchAllUsersHistoryData()
  }, [])

  //load broker histories
  useEffect(() => {
    fetchAllBrokersHistoryData()
  }, [])

  //load user portfolio and risk and history
  useEffect(() => {
    if (!connected) {
      return
    }

    fetchPortUser(accountAddress as string)
    fetchUserWalletBalances(accountAddress as string)
  }, [connected, accountAddress])

  //fetch points
  useEffect(() => {
    fetchPoints()
  }, [])

  //fetch broker and histories on timeout
  useEffect(() => {
    const fetchAllInterval = () => {
      fetchAppData()
      if (!isEmptyOrNil(underlyingNames)) {
        const hasAddress = accountAddress
        const addressOrEmpty = hasAddress ? accountAddress : ''
      }
    }

    const intervalId = setInterval(fetchAllInterval, 300000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  //fetch user on timeout
  useEffect(() => {
    if (connected) {
      const fetchUser = () => {
        fetchPortUser(accountAddress as string)
        fetchUserWalletBalances(accountAddress as string)
      }

      const intervalId = setInterval(fetchUser, 300000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [connected, accountAddress])

  //check if broker fetch errored
  useEffect(() => {
    if (brokersErroredAfterRetry) {
      const txErrorState = {
        title: 'Network Overload!',
        message: `We're currently unable to load the broker data due to the immense amount of traffic occurring on the Movement network at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected. Please refresh or try again later.`
      }
      setTxErrorState(txErrorState)
    }
  }, [brokersErroredAfterRetry])

  //check if portfolio fetch errors
  useEffect(() => {
    if (portfolioErroredAfterRetry) {
      const txErrorState = {
        title: 'Network Overload!',
        message: `We're currently unable to load the portfolio data due to the immense amount of traffic occurring on the Movement network at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected. Please refresh or try again later.`
      }
      setTxErrorState(txErrorState)
    }
  }, [portfolioErroredAfterRetry])

  //referral code check
  const [referralCode, setReferralCode] = useState<string | null>(null)

  useEffect(() => {
    const code = query.get('refCode')
    if (code) {
      setReferralCode(code)
      openReferralBanner()
    }
  }, [query])

  useEffect(() => {
    if (referralCode) {
      openDoReferModal()
    }
  }, [referralCode])

  const hideScrollClass = dashSelected && !connected ? 'hide-scroll' : ''

  return (
    <>
      <Context.Provider value={{ context, setContext }}>
        <div id="App" className={hideScrollClass}>
          <ReferralBanner />
          <TopNav />
          <section className="section-wrapper">
            <div className="section">
              <Routing />
            </div>
          </section>
          <section className="section-wrapper">
            <div className="section no-height">
              <Footer />
            </div>
          </section>
        </div>
        <AppModals />
        <WidgetContainer />
        <Toaster position="bottom-left" />
      </Context.Provider>
    </>
  )
}

export default InnerApp
