import { ChartCardWrapper } from 'components/charts/ChartCardWapper'
import SimpleTreeMap from 'components/charts/TreeMap'
import { TwoLinePlatformTotals } from 'components/charts/TwoLinePlatformTotals'
import MarketsList from 'components/markets/MarketsList'
import { MarketsListMobile } from 'components/markets/MarketsListMobile'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { selectAllBrokersHistoryLoaded } from 'state/slices/app/allBrokersHistory'
import {
  calcBrokerTotalBorrowDollars,
  calcBrokerTotalLendDollars,
  selectBrokersLoaded,
  selectBrokersWithMeta,
  selectPricesFromBrokers
} from 'state/slices/app/brokers'
import { formatCurrency } from 'toolbox/format'

const COLORS_LEND = [
  'rgba(0, 221, 92, 0.40)',
  'rgba(0, 221, 92, 0.3)',
  'rgba(0, 221, 92, 0.2)',
  'rgba(0, 221, 92, 0.15)',
  'rgba(0, 221, 92, 0.07)'
]

const COLORS_BORROW = [
  'rgba(255, 255, 0, 0.4)',
  'rgba(255, 255, 0, 0.3)',
  'rgba(255, 255, 0, 0.2)',
  'rgba(255, 255, 0, 0.15)',
  'rgba(255, 255, 0, 0.07)'
]

export interface DataPoint {
  name: string
  balance: number
}

export function Markets() {
  const brokers = useAppSelector(selectBrokersWithMeta)
  const brokersLoaded = useAppSelector(selectBrokersLoaded)
  const prices = useAppSelector(selectPricesFromBrokers)

  function filterBalancesForMinimumShare(assets: DataPoint[]): DataPoint[] {
    const totalBalance = assets.reduce((sum, asset) => sum + asset.balance, 0)
    const threshold = totalBalance * 0.01
    return assets.filter((asset) => asset.balance >= threshold)
  }

  const dataLend = useMemo(
    () =>
      brokers
        .map((b) => {
          if (b.tokenMeta.discontinued) return null
          const name = b.underlyingAsset.name
          const balance = calcBrokerTotalLendDollars(brokersLoaded, brokers, prices, name)

          if (balance === 0) return null
          return { name: b.tokenMeta.ticker, balance }
        })
        .filter((b) => b !== null),
    [brokers, brokersLoaded, prices]
  )

  const dataBorrow = useMemo(
    () =>
      brokers
        .map((b) => {
          if (b.tokenMeta.discontinued) return null
          const name = b.underlyingAsset.name
          const balance = calcBrokerTotalBorrowDollars(brokersLoaded, brokers, prices, name)
          if (balance === 0) return null
          return { name: b.tokenMeta.ticker, balance }
        })
        .filter((b) => b !== null),
    [brokers, brokersLoaded, prices]
  )

  const minShareLend = filterBalancesForMinimumShare(dataLend)
  const minShareBorrow = filterBalancesForMinimumShare(dataBorrow)

  const sortedLend = useMemo(
    () => [...minShareLend].sort((a, b) => b.balance - a.balance),
    [minShareLend]
  )
  const sortedBorrow = useMemo(
    () => [...minShareBorrow].sort((a, b) => b.balance - a.balance),
    [minShareBorrow]
  )

  const lendTotal = useMemo(
    () => minShareLend.reduce((acc, curr) => acc + curr.balance, 0),
    [minShareLend]
  )
  const borrowTotal = useMemo(
    () => minShareBorrow.reduce((acc, curr) => acc + curr.balance, 0),
    [minShareBorrow]
  )

  const cardWrapperLend = useMemo(
    () => ({
      title: 'Total lend composition',
      dataPointValue: formatCurrency(lendTotal)
    }),
    [lendTotal]
  )

  const cardWrapperBorrow = useMemo(
    () => ({
      title: 'Total borrow composition',
      dataPointValue: formatCurrency(borrowTotal)
    }),
    [borrowTotal]
  )

  const brokerHistoriesLoaded = useAppSelector(selectAllBrokersHistoryLoaded)

  return (
    <div className="markets-page">
      <h1>Platform Stats</h1>
      <div className="two-line-container">
        <TwoLinePlatformTotals isLoaded={brokerHistoriesLoaded} />
      </div>
      <div className="chart-cards">
        <ChartCardWrapper {...cardWrapperLend}>
          <SimpleTreeMap data={sortedLend} colors={COLORS_LEND} />
        </ChartCardWrapper>
        <ChartCardWrapper {...cardWrapperBorrow}>
          <SimpleTreeMap data={sortedBorrow} colors={COLORS_BORROW} />
        </ChartCardWrapper>
      </div>
      <div className="markets">
        <h1>Markets</h1>
        <MarketsList />
        <MarketsListMobile />
      </div>
    </div>
  )
}
