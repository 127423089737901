import { MetaBroker } from 'state/mock'
import { openDetailsWithBroker } from 'state/slices/ui/form'
import InfoPoint from '../common/InfoPoint'
import { formatCurrency, formatPercentage, prettyTokenBal } from 'toolbox/format'
import { calcAPY, calcUtil } from 'toolbox/calc'
import { SimpleMetaDisplay } from './SimpleMetaDisplay'
import { useAppSelector } from 'state/hooks'
import { selectIsBlocked, showBlock } from 'state/slices/ui/dash'

export const COLLATERAL = 'Supply'
export const BORROW = 'Borrow'
export interface MarketsRowProps {
  b: MetaBroker
}

function MarketsRow({ b }: MarketsRowProps) {
  const isBlocked = useAppSelector(selectIsBlocked)

  const openDetails = () => (isBlocked ? showBlock() : openDetailsWithBroker(b))
  const token = b.tokenMeta

  if (!b || !token.logo || token.discontinued) return null
  const utilization = calcUtil(b)
  const assetPrice = b.underlyingAsset.price

  const meta = {
    firstIcon: token.logo,
    value: token.ticker,
    onClickCallback: openDetails,
    noHover: true
  }

  const borrowAPR = b.interestRate
  const borrowAPY = calcAPY(borrowAPR)
  const supplyAPY = borrowAPY * utilization
  const totalLend = b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying
  const totalBorrow = b.scaledTotalBorrowedUnderlying
  const totalLendValue = totalLend * assetPrice
  const totalBorrowValue = totalBorrow * assetPrice
  const totalAvailableValue = b.scaledAvailableLiquidityUnderlying * assetPrice

  const doDisplay = () => {
    return (
      <div className="market-row clickable" onClick={openDetails}>
        <div className="market-column">
          <SimpleMetaDisplay {...meta} />
        </div>
        <div className="market-column">
          <p className="point">{formatPercentage(utilization)}</p>
        </div>
        <div className="market-column">
          <p className="point">{formatPercentage(supplyAPY)}</p>
        </div>
        <div className="market-column">
          <p className="point">{formatPercentage(borrowAPR)}</p>
        </div>
        <div className="market-column">
          <InfoPoint
            value={prettyTokenBal(b.scaledAvailableLiquidityUnderlying)}
            sub={formatCurrency(totalAvailableValue)}
          />
        </div>
        <div className="market-column">
          <InfoPoint value={prettyTokenBal(totalBorrow)} sub={formatCurrency(totalBorrowValue)} />
        </div>
        <div className="market-column">
          <InfoPoint value={prettyTokenBal(totalLend)} sub={formatCurrency(totalLendValue)} />
        </div>
      </div>
    )
  }

  return <div className="market-columns">{doDisplay()}</div>
}

export default MarketsRow
