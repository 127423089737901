import pontemIcon from 'assets/logos/pontem.svg'
import { useWallet, WalletName } from '@aptos-labs/wallet-adapter-react'
import connectedIcon from 'assets/icons/connected.svg'
import { Wallet } from '@aptos-labs/wallet-adapter-react'
import { WalletContext } from 'components/wallet/MetaWalletContext'
import { useContext, useEffect, useState } from 'react'
import { BadBrowserFallback, isUnsupportedBrowser } from './BadBrowserFallback'
import { logAndToastError } from 'toolbox/toast'
import { closeWidget } from 'state/slices/ui/widget'
import { isEmptyOrNil } from 'toolbox/account'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import { WalletInfo } from '@aptos-labs/wallet-adapter-core'

function AccountWalletsList() {
  const { browser } = useContext(WalletContext)
  const isUnsupported = isUnsupportedBrowser(browser)
  const { wallet, wallets, connect, network, connected } = useWallet()
  const name = wallet?.name
  const [isConnecting, setIsConnecting] = useState('')

  const connectWallet = async (name: WalletName) => {
    setIsConnecting(name)
    try {
      await connect(name)
    } catch (e) {
      setIsConnecting('false')
      logAndToastError('Wallet', 'Wallet Connection Failed')
    }
  }

  useEffect(() => {
    if (connected) {
      setIsConnecting('')
    }
  }, [connected])

  const emptyInfoBox: InfoBoxProps = {
    color: 'yellow',
    showBox: isEmptyOrNil(wallets),
    text: 'No supported wallets found',
    fullWidth: true
  }

  if (isEmptyOrNil(wallets)) {
    return (
      <>
        <div className="account-wallets-outer">
          <InfoBox {...emptyInfoBox} />
        </div>
      </>
    )
  }

  //convert network to lowercase string
  const convertToLower = network?.name.toLowerCase()
  const envNet = process.env.REACT_APP_APTOS_NETWORK_SHORT
  // Nightly lists movement testnet under "custom" network
  const testnetNetworkNames = ['testnet', 'custom']
  const isMainnet = envNet === 'mainnet'
  const isNetworkOk = isMainnet ? true : testnetNetworkNames.includes(convertToLower)

  function renderWalletContainer(wallet: WalletInfo, additionalText = '', noStyle = false) {
    return (
      <div
        className={noStyle ? 'vert-align' : 'funding-wallet-container'}
        onClick={() => connectWallet(wallet.name)}
        key={wallet.name}>
        <img src={wallet.icon} alt={wallet.name} className="wallet-icon" />
        <h3>{wallet.name}</h3>
        {!!additionalText && <h4>({additionalText})</h4>}
      </div>
    )
  }

  function renderConnectedWalletContainer(wallet: WalletInfo) {
    return (
      <div className="funding-wallet-container full connected">
        <div className="lined">
          <img src={name === 'Pontem' ? pontemIcon : wallet?.icon} alt={'Wallet Connected'} />
          <h3>Connected</h3>
        </div>
        <img src={connectedIcon} alt={'Connected'} className="connect-icon" />
      </div>
    )
  }

  const pageOneDisplay = wallets.map((w, i) => {
    const isConnectingWallet = isConnecting === w.name

    if (w.readyState !== 'Installed') {
      const isMSafe = w.name === 'MSafe'
      const installationText = isMSafe ? 'Go to MSafe' : 'Install Extension'
      return (
        <a
          href={w.url}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
          className="funding-wallet-container">
          {renderWalletContainer(w, installationText, isMSafe)}
        </a>
      )
    } else {
      const connectingText = isConnectingWallet ? 'Connecting...' : ''
      return renderWalletContainer(w, connectingText)
    }
  })

  const infoBoxBlueConnected: InfoBoxProps = {
    color: 'yellow',
    showBox: isNetworkOk,
    text: `${name} wallet connected to ${envNet}`,
    fullWidth: true
  }

  const infoBoxRedConnected: InfoBoxProps = {
    color: 'red',
    showBox: !isNetworkOk,
    text: `Network: ${envNet} not detected. Check your wallet settings and refresh the page to try again. Wallet Network: ${network?.name}`,
    fullWidth: true
  }

  const infoBoxBlue: InfoBoxProps = {
    color: 'yellow',
    showBox: true,
    text: 'Select a wallet to connect with MovePosition on Aptos Movement',
    fullWidth: true
  }

  return connected ? (
    <>
      <div className="account-wallets-outer">
        <InfoBox {...infoBoxBlueConnected} />
        <InfoBox {...infoBoxRedConnected} />
        {renderConnectedWalletContainer(wallet)}
        <button className="sp-btn tertiary full-w" onClick={closeWidget}>
          Close
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="account-wallets-outer">
        <InfoBox {...infoBoxBlue} />
        <div className="account-wallet-list">{pageOneDisplay}</div>
      </div>
    </>
  )
}

export default AccountWalletsList
