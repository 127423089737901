import { TwoLineInterestRates } from 'components/charts/TwoLineInterestRates'
import { OneLinePrice } from 'components/charts/OneLinePrice'
import { DetailsBox } from 'components/brokerDetails/DetailsBox'
import { LabelRow } from 'components/brokerDetails/LabelRow'
import { TopDetails } from 'components/brokerDetails/TopDetails'
import {
  getPriceHistoryByName,
  selectPriceHistories,
  selectPriceHistoriesLoaded
} from 'state/slices/app/priceHistories'
import { useAppSelector } from 'state/hooks'
import { BarLineUtilization } from 'components/charts/BarLineUtilization'
import { selectActiveBroker } from 'state/slices/ui/form'
import { selectPositionByName } from 'state/slices/user/portfolio'
import { getWalletBalanceByName, selectWalletBalances } from 'state/slices/user/walletBalances'
import { useEffect, useRef, useState } from 'react'
import { selectStickyOn, setSticky, unsetSticky } from 'state/slices/ui/widget'
import {
  getBrokerHistoryByName,
  selectAllBrokersHistory,
  selectAllBrokersHistoryLoaded
} from 'state/slices/app/allBrokersHistory'
import { InterestRateModel } from 'components/charts/InterestRateModel'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { openWidgetSignIn } from 'state/slices/ui/widget'
import ParamList from 'components/txForm/ParamList'

export default function BrokerDetails() {
  const { connected } = useWallet()
  const b = useAppSelector(selectActiveBroker)
  const name = b?.underlyingAsset.name
  const depNoteName = b?.depositNote?.name
  const loanNoteName = b?.loanNote?.name
  const suppliedBalance = useAppSelector((s) => selectPositionByName(s))(depNoteName)
  const loanBalance = useAppSelector((s) => selectPositionByName(s))(loanNoteName)
  const allHistories = useAppSelector(selectPriceHistories)
  const priceHistoriesLoaded = useAppSelector(selectPriceHistoriesLoaded)
  const walletBalances = useAppSelector(selectWalletBalances)
  const scrollableRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const histories = useAppSelector(selectAllBrokersHistory)
  const brokerHistory = getBrokerHistoryByName(b?.underlyingAsset.name, histories)
  const brokerHistoriesLoaded = useAppSelector(selectAllBrokersHistoryLoaded)
  const interestData = brokerHistory?.interestRateHistory
  const isStickyOn = useAppSelector(selectStickyOn)

  useEffect(() => {
    if (scrollTop > 0) {
      setSticky()
    }
    if (scrollTop === 0) {
      unsetSticky()
    }
  })

  useEffect(() => {
    const currentScrollableElement = scrollableRef.current

    const handleScroll = () => {
      if (currentScrollableElement) {
        setScrollTop(currentScrollableElement.scrollTop)
      }
    }

    if (currentScrollableElement) {
      currentScrollableElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentScrollableElement) {
        currentScrollableElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const priceHistory = getPriceHistoryByName(name, allHistories)
  const walletBalance = getWalletBalanceByName(walletBalances, name)

  const balanceData = [
    {
      balance: suppliedBalance,
      dollars: suppliedBalance * b?.depositNote.price,
      name: 'Supplied',
      walletBalance: walletBalance
    },
    {
      balance: loanBalance,
      dollars: loanBalance * b?.loanNote.price,
      name: 'Borrowed'
    },
    {
      balance: walletBalance,
      dollars: walletBalance * b?.underlyingAsset.price,
      name: 'Wallet balance'
    }
  ]

  const feeParams = [
    {
      label: 'Interest rate spread',
      value: '15%'
    },
    {
      label: 'Stability fee',
      value: '0.15%'
    },
    {
      label: 'Liquidation penalty',
      value: '10%'
    }
  ]

  return (
    <div className="broker-details">
      <div className="section-wrapper">
        <div className="section ">
          <TopDetails isStickyOn={isStickyOn} />
          <div className="bottom-details">
            <div className="left-details extra-margin" ref={scrollableRef}>
              {/* <DetailsBox title="Price" short={true}> */}
              {/* <OneLinePrice
                  data={priceHistory}
                  isLoaded={priceHistoriesLoaded}
                  price={b?.underlyingAsset.price}
                /> */}
              {/* </DetailsBox> */}
              <DetailsBox title="Interest rate" short={true}>
                <TwoLineInterestRates isLoaded={brokerHistoriesLoaded} data={interestData} />
              </DetailsBox>
              <DetailsBox title="Utilization rate" short={true}>
                <BarLineUtilization isLoaded={brokerHistoriesLoaded} name={name} />
              </DetailsBox>
              <DetailsBox title="Interest rate model">
                <InterestRateModel b={b} />
              </DetailsBox>
            </div>
            <div className="right-details">
              <DetailsBox title="Your balances" shadow={true}>
                {connected && (
                  <>
                    <LabelRow {...balanceData[1]} isSupply={false} />
                    <LabelRow {...balanceData[0]} isSupply={true} />
                  </>
                )}
                <hr className="hr" />
                {connected ? (
                  <LabelRow {...balanceData[2]} simple />
                ) : (
                  <button className="sp-btn primary full-w" onClick={openWidgetSignIn}>
                    Connect wallet
                  </button>
                )}
              </DetailsBox>
              <div className="fees-container">
                <ParamList params={feeParams} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
