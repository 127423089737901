import copy from 'assets/icons/copy.svg'
import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import tenPercent from 'assets/icons/ten-percent.svg'
import { useAppSelector } from 'state/hooks'
import {
  openReferralModal,
  selectPointsTotalsLoaded,
  selectReferralCount,
  selectTotalReferralPoints
} from 'state/slices/app/points'
import { selectReferralCode } from 'state/slices/user/referral'
import { logAndToastError, toastSuccess } from 'toolbox/toast'
import { SPointsHistory } from '@concordia/super-sdk/src/io'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { commaFloor, formatPublicKeyDisplay } from 'toolbox/format'
import { selectReferees } from 'state/slices/user/referralsByUser'

export function Referral({ userPoints }: { userPoints: SPointsHistory }) {
  const pointsTotalsLoaded = useAppSelector(selectPointsTotalsLoaded)
  const { connected, account } = useWallet()
  const networkShort = process.env.REACT_APP_APTOS_NETWORK_SHORT
  const isTestnet = networkShort === 'testnet'
  const refCode = useAppSelector(selectReferralCode)
  const address = account?.address || ''
  const referralCount = useAppSelector((s) => selectReferralCount(s, address))
  const referralTotal = useAppSelector((s) => selectTotalReferralPoints(s, address))

  const referralPoints: DataPointProps[] = [
    {
      label: 'Referral Points',
      value: pointsTotalsLoaded && connected ? commaFloor(referralTotal) : '--',
      large: true
    },
    {
      label: '# of referees',
      value: pointsTotalsLoaded && connected ? referralCount : '--',
      large: true
    }
  ]

  const referralPointsDisplay = referralPoints.map((p, i) => <DataPoint key={i} {...p} />)

  const { hasReferees, referees } = useAppSelector(selectReferees)

  const link = isTestnet
    ? `https://testnet.moveposition.xyz/?refCode=${refCode}`
    : `https://app.moveposition.xyz/?refCode=${refCode}`

  const handleButtonClick = (e) => {
    e.stopPropagation()
    if (refCode === '' || !refCode) {
      logAndToastError('Referral code not found: ', 'Code not found: minimum 100 points required')
    } else {
      navigator.clipboard.writeText(link as string)
      openReferralModal()
      toastSuccess('Link copied!')
    }
  }

  return (
    <div className="referral-card">
      <h3>My Referral</h3>
      <div className="link-row">
        <div className="link-row-left">
          <div className="ten-percent">
            <img src={tenPercent} alt="10%" />
            <p>10%</p>
          </div>
          <p className="link-row-info">
            For each referral, you get <span className="orange">10%</span> of their daily points
            forever, and they get a <span className="orange">2.0x multiplier</span> for 10 days.
          </p>
        </div>
        <div className="copy-link" onClick={handleButtonClick}>
          <p>Copy referral link</p>
          <img src={copy} alt="copy" />
        </div>
      </div>
      <div className="referral-points">{referralPointsDisplay}</div>
      {!!hasReferees && (
        <div className="referee-list">
          <div className="referee-cols">
            <p>Referee address</p>
            <p>Points you got</p>
          </div>
          {referees.map((r, i) => (
            <div className="referee-row" key={i}>
              <p>{formatPublicKeyDisplay(r.referee_address)}</p>
              <p>{commaFloor(r.referal_points)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
